import React from "react"
import { Link } from "gatsby"

export default class blogLink extends React.Component {
  render() {
    return (
      <div className="bg-white ">
        <div className=" bg-white max-w-screen-xl mx-auto ">
          <div className="mb-6 ">
            <nav className="tracking-wider text-left text-base sm:text-sm 2xl:text-lg grid gap-3 grid-cols-2 sm:grid-cols-4 justify-items-center">
              <div className="py-2">
                <Link
                  to="/blog/"
                  className="underline flex text-gray-500 hover:text-new-green"
                >
                  Latest
                </Link>
              </div>

              <div className="py-2">
                <Link
                  to="/math-for-cs/"
                  className="underline flex  text-gray-500 hover:text-new-green"
                >
                  Math
                </Link>
              </div>

              <div className="py-2">
                <Link
                  to="/algorithmic-puzzles/"
                  className="underline flex text-gray-500 hover:text-new-green"
                >
                  Puzzles
                </Link>
              </div>

              <div className="py-2">
                <Link
                  to="/popular-algorithms/"
                  className="underline flex text-gray-500 hover:text-new-green"
                >
                  Algorithms
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}
