import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Footer from "../components/footer"
import BlogLink from "../components/blogLink"
import NavigationBar from "../components/Navbar"
import ContactForm from "../components/contactForm"
import { Helmet } from "react-helmet"

export const BlogPostTemplate = ({
  posttitle,
  author,
  reviewer,
  tags,
  path,
  content,
  similarBlogs,
}) => {
  var authorName = null
  var authorLink = null
  var reviewerName = null
  var reviewerLink = null

  if (String(author) === "Enjoy Mathematics") {
    authorName = "Enjoy Mathematics"
    authorLink = "https://www.linkedin.com/company/enjoy-algorithms"
  }

  if (String(author) === "Prasanna") {
    authorName = "Prasanna Bartakke"
    authorLink = "https://www.linkedin.com/in/prasannabiitm/"
  }

  if (String(author) === "Shubham Gautam") {
    authorName = "Shubham Gautam"
    authorLink = "https://www.linkedin.com/in/shubhamgautamsuper30/"
  }

  if (String(author) === "Ravish Raj") {
    authorName = "Ravish Raj"
    authorLink = "https://www.linkedin.com/in/ravishkumar2/"
  }

  if (String(author) === "Vishal Das") {
    authorName = "Vishal Das"
    authorLink = "https://www.linkedin.com/in/vishal-das-2a2808201/"
  }

  if (String(reviewer) === "Shubham Gautam") {
    reviewerName = "Shubham Gautam"
    reviewerLink = "https://www.linkedin.com/in/shubhamgautamsuper30/"
  }

  if (String(reviewer) === "Ravish Raj") {
    reviewerName = "Ravish Raj"
    reviewerLink = "https://www.linkedin.com/in/ravishkumar2/"
  }

  if (String(reviewer) === "Enjoy Mathematics") {
    reviewerName = "Enjoy Mathematics"
    reviewerLink = "https://www.linkedin.com/company/enjoy-algorithms"
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{posttitle}</title>
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <div className="bg-white">
        <div className="bg-white relative pt-6 px-2 md:pt-8 pb-10 overflow-hidden mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
          <div className="relative px-1">
            <div className="mx-auto">
              <h1 className="p-2 text-2xl tracking-wider xl:text-3xl leading-7 text-center text-gray-900">
                {posttitle}
              </h1>
            </div>

            <div className="flex flex-wrap content-center px-3 mt-6">
              <p className="m-1 text-base xl:text-lg text-gray-500 font-medium ">
                Related tags:
              </p>
              {tags &&
                tags.map((tag, index) => (
                  <Link
                    key={index}
                    to={`/tags/${tag}/`}
                    className="items-center hover:text-new-green underline tracking-wider px-2 py-1 text-sm sm:text-base xl:text-lg leading-6 text-gray-500"
                  >
                    {tag}
                  </Link>
                ))}
            </div>

            <div
              className="px-2 sm:px-4 mt-6 tracking-wider select-none max-w-none prose xl:prose-lg 2xl:prose-xl"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>

            <div className="grid grid-cols-1 px-2 sm:px-4 mt-6 sm:grid-cols-2">
              {author ? (
                <a
                  href={authorLink}
                  className="tracking-wider text-gray-500 text-base  sm:text-sm 2xl:text-lg "
                >
                  <div className="flex">
                    <p className="leading-5">Author:</p>
                    <p className="ml-2 underline tracking-wider leading-5 hover:text-new-green">
                      {authorName}
                    </p>
                  </div>
                </a>
              ) : null}

              {reviewer ? (
                <a
                  href={reviewerLink}
                  className="mt-4 tracking-wider text-base  sm:text-sm 2xl:text-lg text-gray-500 sm:mt-0"
                >
                  <div className="flex">
                    <p className="leading-5 ">Reviewer:</p>
                    <p className="ml-2 leading-5 tracking-wider underline hover:text-new-green">
                      {reviewerName}
                    </p>
                  </div>
                </a>
              ) : null}
            </div>
          </div>

          <div className="flex mt-6 justify-end">
            <p className="mr-2 tracking-wider text-base sm:text-sm 2xl:text-lg text-gray-500 ">
              Share on social media:
            </p>
            <div className="ml-2">
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.enjoyalgorithms.com${path}&text=${posttitle}`}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-linkedin"
                >
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                  <rect x="2" y="9" width="4" height="12" />
                  <circle cx="4" cy="4" r="2" />
                </svg>
              </a>
            </div>

            <div className="ml-2">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/intent/tweet?url=https://www.enjoyalgorithms.com${path}&text=${posttitle}`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-twitter"
                >
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                </svg>
              </a>
            </div>
            <div className="ml-2">
              <div
                class="fb-share-button"
                data-href="https://developers.facebook.com/docs/plugins/"
                data-layout="button_count"
                data-size="small"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://www.enjoyalgorithms.com${path}`}
                  class="fb-xfbml-parse-ignore"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-facebook"
                  >
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="ml-2">
              <a
                href={`https://api.whatsapp.com/send?text=${posttitle}: https://www.enjoyalgorithms.com${path}`}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-message-circle"
                >
                  <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
                </svg>
              </a>
            </div>
          </div>

          <ContactForm />
          <div className="mt-2">
            <BlogLink/>
            </div>
        </div>

        {similarBlogs && similarBlogs.length ? (
          <div className="mx-auto">
            <div className="">
              <h3 className="mx-auto px-5 mb-2 tracking-wider text-xl lg:text-2xl 2xl:text-3xl text-center">
                More blogs to explore
              </h3>
            </div>
            <div className="relative p-4 md:p-8">
              <div className="relative mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
                <div className="grid h-full gap-10 sm:grid-cols-2 mx-auto mt-4">
                  {similarBlogs
                    .sort(() => Math.random() - Math.random())
                    .slice(-6)
                    .map(({ node: post }) => (
                      <Link to={post.frontmatter.path} key={post.id}>
                        <article
                          className={`h-full ${
                            post.frontmatter.featuredpost
                              ? "is-featured"
                              : "bg-white"
                          }`}
                        >
                          <div className="flex flex-col h-full overflow-hidden bg-white shadow md:shadow-lg rounded">
                            <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                              <div className="flex-1">
                                <a href="/#" className="block">
                                  <Link
                                    className="mt-3 text-lg tracking-wider 2xl:text-xl leading-6 bg-white text-cool-gray-900"
                                    to={post.frontmatter.path}
                                  >
                                    {" "}
                                    {post.frontmatter.title}{" "}
                                  </Link>

                                  <Link to={post.frontmatter.path}>
                                    <p className="mt-3 leading-6 tracking-wider overflow-hidden line-clamp-3 text-xs 2xl:text-sm bg-white text-gray-500">
                                      {post.frontmatter.description}
                                    </p>
                                  </Link>
                                </a>
                              </div>

                              <div className=" items-center mt-4 mb-2">
                                <button
                                  class="readmore-button"
                                  type="submit"
                                  onClick={e => {
                                    e.preventDefault()
                                    window.location.href = post.frontmatter.path
                                  }}
                                >
                                  <div className=" flex bg-new-green tracking-wider shadow hover:bg-green-50 hover:text-gray-900 text-white text-base 2xl:text-lg px-5 py-3 rounded">
                                    <p className="mr-2 ">Read More</p>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-arrow-right-circle"
                                    >
                                      <circle cx="12" cy="12" r="10" />
                                      <polyline points="12 16 16 12 12 8" />
                                      <line x1="8" y1="12" x2="16" y2="12" />
                                    </svg>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </article>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="mt-6">
        <Footer />
      </div>
    </div>
  )
}

const BlogPost = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const similar = allMarkdownRemark.edges
  // console.log(html)
  return (
    <BlogPostTemplate
      posttitle={frontmatter.title}
      author={frontmatter.author}
      reviewer={frontmatter.reviewer}
      tags={frontmatter.tags}
      path={frontmatter.path}
      content={html}
      description={frontmatter.description}
      similarBlogs={similar}
    />
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($path: String!, $id: String!, $tags: [String]) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        author
        description
        path
        reviewer
        tags
        title
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: $tags }, visibility: { eq: true } }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          frontmatter {
            description
            path
            tags
            title
            visibility
          }
          id
          excerpt(pruneLength: 150)
        }
      }
    }
  }
`