import React from "react"
export default class ContactFormPage extends React.Component {
  state = {
    selectedOption: null,
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    )
  }

  render() {
    return (
      <div className="px-4 pb-6 mt-6 mx-auto">
        <div className="max-w-screen-xl mx-auto lg:max-w-none">
          <form
            name="contact"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            className="grid grid-cols-1 row-gap-6"
          >
            <p class="hidden">
              <label>
                Don’t fill this out if you’re human: <input name="bot-field" />
              </label>
            </p>
            <input type="hidden" name="form-name" value="contact" />

            <div className="my-2">
              <label htmlFor="full_name" className="sr-only">
                Full Name
              </label>
              <div className="relative border border-gray-300 rounded">
                <input
                  name="fullname"
                  id="full_name"
                  className="block w-full px-4 py-3 placeholder-gray-500 form-input"
                  placeholder="Name"
                  onFocus={e => (e.target.placeholder = "")}
                  onBlur={e => (e.target.placeholder = "Name")}
                  required
                />
              </div>
            </div>

            <div className="my-2">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <div className="relative border border-gray-300 rounded">
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="block w-full px-4 py-3 placeholder-gray-500 form-input"
                  placeholder="Email"
                  onFocus={e => (e.target.placeholder = "")}
                  onBlur={e => (e.target.placeholder = "Email")}
                  required
                />
              </div>
            </div>

            <div className="my-2">
              <label htmlFor="message" className="sr-only">
                Message
              </label>
              <div className="relative border border-gray-300 rounded">
                <textarea
                  name="message"
                  id="message"
                  rows={5}
                  className="block w-full px-4 py-3 placeholder-gray-500 form-input"
                  placeholder="Message"
                  onFocus={e => (e.target.placeholder = "")}
                  onBlur={e => (e.target.placeholder = "Message")}
                  required
                />
              </div>
            </div>

            <div className="my-4 flex justify-center">
              <span className="inline-flex rounded">
                <button
                  type="submit"
                  className="tracking-wider px-5 py-3 text-lg 2xl:text-xl bg-new-purple text-white rounded hover:bg-green-50 hover:text-gray-900"
                >
                  Share Your Feedback
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
